html.default body  #body {
    padding-left: 25px;
    padding-right: 25px;
    min-width: 280px;
    padding-top: 20px;
    font-family: Karla;
    font-size: 14px;
    margin-top: 57px;
  }


  h1 {
    color: $SHGreen;
    margin-top: 0px;
    margin-bottom: 12px;
    margin-right: 0;
    margin-left: 0;
    font-family: Source Serif Pro;
    font-size: 24pt;
  }

.btn.dropdown-toggle {
    background: white;
    color: $SHBlue;
    text-transform: capitalize;
    height: 44px;
  }


.container-body {
  border:1px solid #ede9df;
  background: white;
  box-shadow: 0px 2px 3px #EDE9DF;
  padding-top: 20px;
  padding: 20px;
  
  margin: 12px;
  
  .form-control {
    border: 1px solid #ddd;
    height: 50px;
  }

  .chat-container{
    .messages-list {
      	background:#F8F6F3;
    }
  }
}

.btn {
  font-weight: bold;
  border-width: 2px;
  font-family: Lato, sans-serif;
}

input[type=text],textarea  {
  display: block;
  width: 100%;
  height: 38px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.78571;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

 .hidden { display:none; }

 a:hover {
 cursor:pointer;
}
a.whendo-disabled {
  opacity: 38%;
  cursor: not-allowed;
}

.chatTooltip {
  margin-top: 1%;
}


.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px
}