.modal-dialog {
  
  padding-top: 80px;
  
  .sh-btn {
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 10px;
  }
}

.requiredCtrl {
  
  &:after {
    content: "*";
    color: $brand-danger;
  }
  
}

.info {
  background: $SHBeige;
}

.danger {
  color: white;
  background: $brand-danger;
}

.confirm {
  color: white;
  background: $SHPurple;
}


.modal-shadow-background {
  background-color: rgba(27, 29, 54, 0.7);
}

.close-btn {
  position: absolute;
  top: -30px;
  right: -30px;
}

.sh-modal {
  
  text-align: center;
  
  .modal-content {
    padding: 35px;
    min-height: 200px;
    font-family: Karla;
    font-size: 14pt;
    font-weight: lighter;
  }
  
  .control-label {
    text-align: left;
  }
  
  input.form-control{
    height: 44px;
    &.validation-error {
      border-width: thick;
      border-color: $brand-danger;
    }
  }
  .basic-typeahead-single.validation-error {
    border-width: thick;
    border-color: $brand-danger;
  }
  
  .title {
    font-family: Source Serif Pro;
    color: $SHPurple;
    margin-bottom: 40px;
  }
  
  .sh-btn {
    margin-top: 40px;
    margin-left: 15px;
    margin-right: 15px;
    height: 50px;
    min-width: 175px;
    &:disabled {
      background: #cccccc5c;
      cursor: not-allowed;
      pointer-events: visible;
    }
  }
  
  .footnote {
    margin-top: 25px;
    display: block;
    font-size: 12pt;
    color: #A09C90;
  }
}

.dark-modal {
  
  .modal-content {
    background: rgba(38, 40, 75, 0.8);
  }
  
  .modal-body {
    color: #FAF8F5;
  }

    .title {
    font-family: Source Serif Pro;
    color: #fff;
    margin-bottom: 40px;
  }
}
